import './App.css';
import React,{ useState } from 'react';
import FrontPage from './FrontPage';
import OnewaySearch from './OnewaySearch';
import RoundTripOb from './components/RoundTripOb';
import RoundTripIb from './components/RoundTripIb';
import RoundTripIbOb from './components/RoundTripIbOb';
import Multicity from './components/Multicity';
import CustomerInfo from './components/CustomerInfo';
import BookingOneway from './BookingOneway';
import PaymentCC from './components/PaymentCC';
import BookingIBOB from './BookingIBOB';
import CCAvenueRedirect from './components/CCAvenueRedirect';
import MyTest from './components/MyTest';
import PaymentStatus from './components/PaymentStatus';
import { createRoot } from "react-dom/client";
import RedirTo from './components/RedirTo';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomerInfoIBOB from './components/CustomerInfoIBOB';
import Testpdf from './Testpdf';
import Messages from './components/Messages';
import FlightList from './components/FlightList';
function App() {
	const [ users,setUsers ] = useState([]);
	const [ text,setText ] = useState('');
	const [ suggestions,setSuggestions ] = useState([]);
	

  return (
  
<div>
	<Router>
    	<Routes>
 		    <Route path="/" element={<FrontPage />} /> 
			 <Route path="/Oneway" element={<OnewaySearch />} />
			 <Route path="/RoundTripOB" element={<RoundTripOb />} />
			 <Route path="/RoundTripIB" element={<RoundTripIb />} />
			 <Route path="/RoundTripCombo" element={<RoundTripIbOb />} />
			 <Route path="/Multicity" element={<Multicity />} />
			 <Route path="/PassengersInfo" element={<CustomerInfo />} />
			 <Route path="/PassengersInfoibob" element={<CustomerInfoIBOB />} />
			 <Route path="/PaymentCCAvenue" element={<PaymentCC />} />
			 <Route path="/booking" element={<BookingOneway />} />  
			 <Route path="/bookingRound" element={<BookingIBOB />} /> 
			 <Route path="/ccavenue-redirect" element={<CCAvenueRedirect />} />  
			 <Route path="/paymentSuccess" element={<PaymentStatus />} />
			 <Route path="/Redire" element={<RedirTo />} />
			 <Route path="/pdf" element={<Testpdf />} />
			 <Route path="/Messages" element={<Messages />} />
			 <Route path="/Flight" element={<FlightList />} />
			 <Route
                    path="/paymentSuccess"
                    render={(props) => (
                        <PaymentStatus
                            {...props}
                            status="success" 
                            orderId="12345" 
                        />
                    )}
                />
	  	</Routes>
  	</Router>
</div>

  );
}

export default App;
