import React,{ useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
//import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import {
    Grid,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Button,
    FormHelperText,
	Autocomplete,
    CircularProgress 
  } from "@mui/material";
import HotelSearch from './HotelSearch';
const validationSchema = yup.object({
	
    password: yup
      .string()
      .min(6, 'Must be at least 6 characters')
      .required('Required'),
  });
  const initialValues = {
	password : 'aaaaaaaaaaa',
  };

const Header = () => {
	const buttonStyle = {
		backgroundColor: '#007bff',
		color: 'white',
		fontSize: '1.5rem',
		width: '40px', 
		height: '40px',
		border: 'none',
		borderRadius: '50%',
		cursor: 'pointer',
		margin: '5px',
	  };
	const [tokenid, setTokenid] = useState('');
	const [markup,setMarkup]	=useState(0);
	const [totPassengers,setTotPassengers]	=useState(1);
	const [markuppercent,setMarkuppercent]	=useState(0);
    const [hed,setHed]	=useState("One-way");
	const [one,setOne]	=useState("");
	const [two,setTwo]	=useState("");
	const [multi,setMulti]	=useState("");
	const [data, setData] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [adults, setAdults] = useState(1);
	const [children, setChildren] = useState(0);
	const [infants, setInfants] = useState(0);
	const [showdiv, setShowDiv] = useState("show");
	const [displaydiv, setDisplayDiv] = useState("none");
	const [depart,setDepart] = useState("");
	const [arrive,setArrive] = useState("");
	const [departLabel,setDepartLabel] = useState("");
	const [arriveLabel,setArriveLabel] = useState("");
	const [clss, setClss] = useState("All");
	const divRefs = useRef([]);
	const currentDate = new Date().toISOString().split('T')[0];
    const [fromdt,setFromdt]=useState(currentDate);
	const [returndt,setReturndt]=useState(currentDate);
	const [passengerscount,setPassengerscount]=useState("1 Passenger");
	const [psngcountstring,setPsngcountstring]=useState("1 Adult");
	const [dtview,setDtview]=useState("none");
	const [responsee, setResponsee] = useState(null);
	const [multicityView, setMulticityView] = useState({ display: 'none' });
	const [divv3, setDivv3] = useState({ display: 'none' });
	const [divv4, setDivv4] = useState({ display: 'none' });
	const [divv5, setDivv5] = useState({ display: 'none' });
	const [divDisplayCount, setDivDisplayCount] = useState(2);
	const [onetwoView, setOnetwoView] = useState({});

	const [from1,setFrom1]=useState("");
    const [to1,setTo1]=useState("");
	const [from1Label,setFrom1Label]=useState("");
    const [to1Label,setTo1Label]=useState("");
    const [traveldt1,setTraveldt1]=useState(currentDate);

    const [from2,setFrom2]=useState("");
    const [to2,setTo2]=useState("");
	const [from2Label,setFrom2Label]=useState("");
    const [to2Label,setTo2Label]=useState("");
    const [traveldt2,setTraveldt2]=useState(currentDate);

    const [from3,setFrom3]=useState("");
    const [to3,setTo3]=useState("");
	const [from3Label,setFrom3Label]=useState("");
    const [to3Label,setTo3Label]=useState("");
    const [traveldt3,setTraveldt3]=useState(currentDate);

    const [from4,setFrom4]=useState("");
    const [to4,setTo4]=useState("");
	const [from4Label,setFrom4Label]=useState("");
    const [to4Label,setTo4Label]=useState("");
    const [traveldt4,setTraveldt4]=useState(currentDate);
    
    const [from5,setFrom5]=useState("");
    const [to5,setTo5]=useState("");
	const [from5Label,setFrom5Label]=useState("");
    const [to5Label,setTo5Label]=useState("");
    const [traveldt5,setTraveldt5]=useState(currentDate);
	const [journeyType,setJourneyType] = useState("1");
	const [cabinClass, setCabinClass] = useState("1");
	const [directFlight,setDirectFlight]=useState("false");
	const [oneStopFlight,setOneStopFlight]=useState("false");
	const [value, setValue] = useState(''); 

	const today = new Date();
	const [minimDate, setMinimDate] = useState(today);
	const [minimDate2, setMinimDate2] = useState(today);
	const [minimDate3, setMinimDate3] = useState(today);
	const [minimDate4, setMinimDate4] = useState(today);
	const [minimDate5, setMinimDate5] = useState(today);
	localStorage.removeItem('useremail');
	const [showActB1, setShowActB1] = useState(" show active");
	const [showActB2, setShowActB2] = useState("");
	const [actB1, setActB1] = useState(" active");
	const [actB2, setActB2] = useState("");  
	let multicount=2;
	const navigate = useNavigate();
	const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
     let segm=[];
	 if(totPassengers>9)
	 {
		alert("Total Passengers should not Exceed 9..");
		return;
	 }
	 if(depart==='' && journeyType<3)
	 {
		alert("Departure And Arrival Should be Enter..");
		return;	
	 }
	 if(arrive==='' && journeyType<3)
		{
		   alert("Departure And Arrival Should be Enter..");
		   return;	
		}
	 if(journeyType==1) 
	 {
		//alert(depart);
		 segm=[
			 {
				 "Origin": depart,
				 "Destination": arrive,
				 "FlightCabinClass": cabinClass,
				 "PreferredDepartureTime": fromdt+"T00: 00: 00",
				 "PreferredArrivalTime": fromdt+"T00: 00: 00"
			 }
		 ];
	 }
	 if(journeyType==2) 
	 {
		 segm=[
			 {
				 "Origin": depart,
				 "Destination": arrive,
				 "FlightCabinClass": cabinClass,
				 "PreferredDepartureTime": fromdt+"T00: 00: 00",
				 "PreferredArrivalTime": fromdt+"T00: 00: 00"
			 },
			 {
				 "Origin": arrive,
				 "Destination": depart,
				 "FlightCabinClass": cabinClass,
				 "PreferredDepartureTime": returndt+"T00: 00: 00",
				 "PreferredArrivalTime": returndt+"T00: 00: 00"
			 }
		 ];
	 }
	 if(journeyType==3) 
	 {
		
		segm=[
			 {
			 "Origin": from1,
			 "Destination": to1,
			 "FlightCabinClass": cabinClass,
			 "PreferredDepartureTime": traveldt1+"T00: 00: 00",
			 "PreferredArrivalTime": traveldt1+"T00: 00: 00"
			 },
			 {
			   "Origin": from2,
			   "Destination": to2,
			   "FlightCabinClass": cabinClass,
			   "PreferredDepartureTime": traveldt2+"T00: 00: 00",
			   "PreferredArrivalTime": traveldt2+"T00: 00: 00"
			 }
		 ];
		 if(from5){
			 multicount=5;
			 segm=[
				 {
				 "Origin": from1,
				 "Destination": to1,
				 "FlightCabinClass": cabinClass,
				 "PreferredDepartureTime": traveldt1+"T00: 00: 00",
				 "PreferredArrivalTime": traveldt1+"T00: 00: 00"
				 },
				 {
				   "Origin": from2,
				   "Destination": to2,
				   "FlightCabinClass": cabinClass,
				   "PreferredDepartureTime": traveldt2+"T00: 00: 00",
				   "PreferredArrivalTime": traveldt2+"T00: 00: 00"
				 },
				 {
				   "Origin": from3,
				   "Destination": to3,
				   "FlightCabinClass": cabinClass,
				   "PreferredDepartureTime": traveldt3+"T00: 00: 00",
				   "PreferredArrivalTime": traveldt3+"T00: 00: 00"
				 },
				 {
					 "Origin": from4,
					 "Destination": to4,
					 "FlightCabinClass": cabinClass,
					 "PreferredDepartureTime": traveldt4+"T00: 00: 00",
					 "PreferredArrivalTime": traveldt4+"T00: 00: 00"
				   },
				   {
					 "Origin": from5,
					 "Destination": to5,
					 "FlightCabinClass": cabinClass,
					 "PreferredDepartureTime": traveldt5+"T00: 00: 00",
					 "PreferredArrivalTime": traveldt5+"T00: 00: 00"
				   }
			 ];
		 }
		 else if(from4){
			 multicount=4;
			 segm=[
				 {
				 "Origin": from1,
				 "Destination": to1,
				 "FlightCabinClass": cabinClass,
				 "PreferredDepartureTime": traveldt1+"T00: 00: 00",
				 "PreferredArrivalTime": traveldt1+"T00: 00: 00"
				 },
				 {
				   "Origin": from2,
				   "Destination": to2,
				   "FlightCabinClass": cabinClass,
				   "PreferredDepartureTime": traveldt2+"T00: 00: 00",
				   "PreferredArrivalTime": traveldt2+"T00: 00: 00"
				 },
				 {
				   "Origin": from3,
				   "Destination": to3,
				   "FlightCabinClass": cabinClass,
				   "PreferredDepartureTime": traveldt3+"T00: 00: 00",
				   "PreferredArrivalTime": traveldt3+"T00: 00: 00"
				 },
				 {
					 "Origin": from4,
					 "Destination": to4,
					 "FlightCabinClass": cabinClass,
					 "PreferredDepartureTime": traveldt4+"T00: 00: 00",
					 "PreferredArrivalTime": traveldt4+"T00: 00: 00"
				   }
			 ];
		 }
		 else if(from3){
			 multicount=3;
		 segm=[
			 {
			 "Origin": from1,
			 "Destination": to1,
			 "FlightCabinClass": cabinClass,
			 "PreferredDepartureTime": traveldt1+"T00: 00: 00",
			 "PreferredArrivalTime": traveldt1+"T00: 00: 00"
			 },
			 {
			   "Origin": from2,
			   "Destination": to2,
			   "FlightCabinClass": cabinClass,
			   "PreferredDepartureTime": traveldt2+"T00: 00: 00",
			   "PreferredArrivalTime": traveldt2+"T00: 00: 00"
			 },
			 {
			   "Origin": from3,
			   "Destination": to3,
			   "FlightCabinClass": cabinClass,
			   "PreferredDepartureTime": traveldt3+"T00: 00: 00",
			   "PreferredArrivalTime": traveldt3+"T00: 00: 00"
			 }
		 ];
		 }
	 
	 }
	 const data=
	 { 
		   "AdultCount": adults, 
		   "ChildCount": children,
		   "InfantCount": infants,
		   "DirectFlight": directFlight,
		   "OneStopFlight": oneStopFlight,
		   "JourneyType": journeyType,
	   
		   "Segments": segm,
		   "Sources": null
	   };
	   
	   localStorage.setItem('adultCount', adults); 
	   localStorage.setItem('childCount', children); 
	   localStorage.setItem('infantCount', infants); 
	   localStorage.setItem('cabinClass', cabinClass);
	   localStorage.setItem('searchdata', JSON.stringify(data));
	   sessionStorage.setItem('Markup', markup);
	   sessionStorage.setItem('Markuppercent', markuppercent);
	   let passString="";
	   if(adults>1){passString=adults+" Adults ";}else if(adults==1){passString=adults+" Adult ";}else{passString=""}
	   if(children>1){passString+=children+" Children ";}else if(children==1){passString+=children+" Child ";}else{passString+=" "}
	   if(infants>1){passString+=infants+" Infants ";}else if(infants==1){passString+=infants+" Infant ";}else{passString+=" "}
	   sessionStorage.setItem('passString', passString);
	   localStorage.setItem('multicount', multicount);  
	   console.log('oooooooooo'+JSON.stringify(data)); 
	   try {
		//alert(JSON.stringify(data)); 
		const response = await axios.post('https://api.travelxpo.in/auth/b2c/search', data);
//alert(JSON.stringify(response.data));
		// Handle the response
		console.log('oooooooooo'+JSON.stringify(response)); 
		//alert(journeyType);
	   if(response.data.Response.Error.ErrorCode=="0" && journeyType==1)
	   { 
		localStorage.setItem('depart', depart);
		localStorage.setItem('arrive', arrive);
		localStorage.setItem('departLabel', departLabel);
		localStorage.setItem('arriveLabel', arriveLabel);
		setResponsee(response.data);
		//const markuppass=markup*(parseInt(adults)+parseInt(children)+parseInt(infants));
		//console.log(response.data);
		sessionStorage.setItem('Markup', markup);
		sessionStorage.setItem('Markuppercent', markuppercent);
		sessionStorage.setItem('fromdt', fromdt);
		navigate('/Oneway', { state: { responsee: response.data } });
	   }
	   else if(response.data.Response.Error.ErrorCode=="0" && journeyType==3)
	   { setResponsee(response.data);
		//localStorage.setItem('IbOb', 'ob'); 
		sessionStorage.setItem('Markuppercent', markuppercent);
		sessionStorage.setItem('Markup', markup);
		localStorage.setItem('multicount', multicount);
		localStorage.setItem('From1', from1);localStorage.setItem('To1', to1);
		localStorage.setItem('From2', from2);localStorage.setItem('To2', to2);
		localStorage.setItem('From3', from3);localStorage.setItem('To3', to3);
		localStorage.setItem('From4', from4);localStorage.setItem('To4', to4);
		localStorage.setItem('From5', from5);localStorage.setItem('To5', to5);
		localStorage.setItem('From1Label', from1Label);localStorage.setItem('To1Label', to1Label);
		localStorage.setItem('From2Label', from2Label);localStorage.setItem('To2Label', to2Label);
		localStorage.setItem('From3Label', from3Label);localStorage.setItem('To3Label', to3Label);
		localStorage.setItem('From4Label', from4Label);localStorage.setItem('To4Label', to4Label);
		localStorage.setItem('From5Label', from5Label);localStorage.setItem('To5Label', to5Label);
		localStorage.setItem('traveldt1',traveldt1);localStorage.setItem('traveldt2',traveldt2);
		localStorage.setItem('traveldt3',traveldt3);localStorage.setItem('traveldt4',traveldt4);
		localStorage.setItem('traveldt5',traveldt5);
		 navigate('/Multicity', { state: { responsee: response.data } });
	   }
	   else if(response.data.Response.Error.ErrorCode=="0" && journeyType==2)
	   { 
		localStorage.setItem('depart', depart);
		localStorage.setItem('arrive', arrive);
		localStorage.setItem('departLabel', departLabel);
		localStorage.setItem('arriveLabel', arriveLabel);
		if(response.data.Response.Results.length==2)
		{
			setResponsee(response.data);
			localStorage.setItem('IbOb', 'ibob'); 
			sessionStorage.setItem('Markuppercent', markuppercent);
			sessionStorage.setItem('Markup', markup);
		sessionStorage.setItem('fromdt', fromdt);
		sessionStorage.setItem('returndt', returndt);
			navigate('/RoundTripOB', { state: { responsee: response.data } });
			
		}
		else{
			sessionStorage.setItem('Markuppercent', markuppercent);
			sessionStorage.setItem('Markup', markup);
		sessionStorage.setItem('fromdt', fromdt);
		sessionStorage.setItem('returndt', returndt);
			navigate('/RoundTripCombo', { state: { responsee: response.data } });
		}
	   }
	   else{
		alert("No result found due to some technical reason, Please try again..");
		
	   }
	  } catch (error) {
		// Handle errors
		console.error(error);
	  }
	

  },
});
	const handleDropdownToggle = () => {
		//setShowDropdown(!showDropdown);

		setShowDiv("show");
		setDisplayDiv("block");
	  };
	  const handleClickButton = (e) => {
		alert("x");
		// Your button click logic here
		console.log('Button clicked!');
	  };
	  const handleClickPlus = (e) => {
		let x=divDisplayCount;
  
       ++x;

       if(x <= 5)
       {
        
        if(x==3)
        {
          
            setDivv3({}); 

        }
        if(x==4)
        {
      
            setDivv4({}); 
            
        }
        if(x==5)
        {  
            setDivv5({}); 
            
        }
        setDivDisplayCount(x);
        }
		
	  };
	  const handleClickMinus = (e) => {
		let x=divDisplayCount;
        if(x >= 3)
        {
         if(x==3)
         {
			setFrom3("");
			setTo3("");
             setDivv3({ display: 'none' }); 

         }
         if(x==4)
         {
			setFrom4("");
			setTo4("");
             setDivv4({ display: 'none' }); 
	

         }
         if(x==5)
         { 
			setFrom5("");
			setTo5("");
             setDivv5({ display: 'none' }); 
			
         }
         --x;
         setDivDisplayCount(x);
         }
 
		
	  };

	const  handleShow  = (number) => {
		return () => {
		if(number==1){
			setShowActB1(" show active");
			setActB1(" active");
			setShowActB2("");
			setActB2("");
		}
		else if(number==2){
			setShowActB1("");
			setActB1("");
			setShowActB2(" show active");
			setActB2(" active");
		}
		
	};


	  };
	
	const handleDateChange = (date, fieldName) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
      
        if (fieldName === 'fromdt') {
            setFromdt(moment(formattedDate).format('YYYY-MM-DD'));
            setReturndt(moment(formattedDate).format('YYYY-MM-DD'));
            setMinimDate(new Date(moment(formattedDate).format('YYYY-MM-DD')));
            
          }
          else if (fieldName === 'returndt') {
            setReturndt(moment(formattedDate).format('YYYY-MM-DD'));
           // setMinimDate(new Date(moment(formattedDate).format('YYYY-MM-DD')));
           
        }else if (fieldName === 'traveldt1') {  
            setTraveldt1(moment(formattedDate).format('YYYY-MM-DD'));
            setTraveldt2(moment(formattedDate).format('YYYY-MM-DD'));
            setMinimDate2(new Date(moment(formattedDate).format('YYYY-MM-DD')));

        }else if (fieldName === 'traveldt2') {
            setTraveldt2(moment(formattedDate).format('YYYY-MM-DD'));
            setTraveldt3(moment(formattedDate).format('YYYY-MM-DD'));
            setMinimDate3(new Date(moment(formattedDate).format('YYYY-MM-DD')));
        }else if (fieldName === 'traveldt3') {
            setTraveldt3(moment(formattedDate).format('YYYY-MM-DD'));
            setTraveldt4(moment(formattedDate).format('YYYY-MM-DD'));
           setMinimDate4(new Date(moment(formattedDate).format('YYYY-MM-DD')));
        }else if (fieldName === 'traveldt4') {
            setTraveldt4(moment(formattedDate).format('YYYY-MM-DD'));
            setTraveldt5(moment(formattedDate).format('YYYY-MM-DD'));
          setMinimDate5(new Date(moment(formattedDate).format('YYYY-MM-DD')));
        }else if (fieldName === 'traveldt5') {
            setTraveldt5(moment(formattedDate).format('YYYY-MM-DD'));
           // setMinimDate5(moment(formattedDate).format('YYYY-MM-DD'));
        }

        };

	  const handleDoneClick = () => {
		setShowDiv("show");
		
		
		const passcount=adults+children+infants;
		if(passcount>9)
		{
alert("Total Passengers can not exceed than 9. ")

		}else{
		setDisplayDiv("none");
		}
		setTotPassengers(passcount);
		let adstr='';
		let chstr='';
		let instr='';
		if(adults==1){adstr=adults+' Adult. ';}
		else if(adults>1){adstr=adults+' Adults. ';}
		if(children==1){chstr=children+' Child. ';}
		else if(children>1){chstr=children+' Children. ';}
		if(infants==1){instr=infants+' Infant. ';}
		else if(infants>1){instr=infants+' Infants. ';}
		setPsngcountstring(adstr+chstr+instr);
		if (passcount==1)
		{setPassengerscount("1 Passenger");}
		else
		{setPassengerscount(passcount+" Passengers");}
		localStorage.setItem('passengerscount', passcount+" Passengers");
		//setShowDropdown(false);
		// Perform any additional actions here
	  };
	  
	  const getList = (list) =>
	  list.map((item) => ({
		value: item.code,
		label: item.code + " -- "+item.name + " -- "+ item.country, 
	  }));
	
	  useEffect(() => {   
		const fetchuser = async () => {
		 try {
			const datt={
				"encryptedOrderId": "xx"
				}
		   const response = await axios.post('https://b2b.travelxpo.in/api/generate-token',datt);	 
		   console.log(response.data);
			const tokennn = response.data.token;
			 		
				setTokenid(tokennn);
				localStorage.setItem('tokennnValue', tokennn);
			
			} catch (error) {
				if (axios.isAxiosError(error)) {
					console.error('Axios Error:', error.response.data);
				  } else {
					console.error('Non-Axios Error:', error);
				  }
				}
		   }
		   fetchuser();   
},[]) ;
useEffect(() => {   
	const fetchmarkup = async () => {
	 try {
		const datt={
			"branchid": 1
			}
	   const response = await axios.post('https://b2b.travelxpo.in/api/get-markup-data',datt);	 
	  // console.log(response.data);
		const markup_type = response.data[0].markup_type;
		const markup_percent = response.data[0].markup_percent;
		const branch_markup = response.data[0].branch_markup;
		if(markup_type=='Amount')
		{
			setMarkup(branch_markup);
			setMarkuppercent(0);
		}
		else if(markup_type=='Percent')
		{
			setMarkup(0);
			const percen=markup_percent/100;
			setMarkuppercent(percen);
		}		
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.error('Axios Error:', error.response.data);
			  } else {
				console.error('Non-Axios Error:', error);
			  }
			}
	   }
	   fetchmarkup();   
},[]) ;
useEffect(() => {

	localStorage.removeItem('flightchargeOb');
	localStorage.removeItem('price');
	localStorage.removeItem('flightchargeIb');
	localStorage.removeItem('priceIb');
	async function fetchData() {
	  try {
		const response = await fetch("https://b2b.travelxpo.in/api/get-airport", {
		  method: "POST",
		  headers: {
			"Content-Type": "application/json",
			// You can add other headers if required
		  },
		  // You may need to send data with your POST request in the body, if necessary
		  // body: JSON.stringify({ key: "value" }),
		});
  
		if (response.ok) {
		  const json = await response.json();
		  setData(getList(json));
		} else {
		  // Handle the response when it's not OK (e.g., error handling)
		  console.error("Error fetching data:", response.status, response.statusText);
		}
	  } catch (error) {
		// Handle network or other errors
		console.error("Network error:", error);
	  }
	}
  
	fetchData();
  }, []);


  const handleSwap = () => {
	const temp=depart;
	const tempLabel = departLabel;
	setDepart(prevDepart => arrive);
	setArrive(temp => depart);
	setDepartLabel(prevDepartLabel => arriveLabel);
	setArriveLabel(tempLabel => departLabel);
  };
  const handleSwapMulty1 =() =>{
	const temp=from1;
	const tempLabel = from1Label;
	setFrom1(prevFrom1 => to1);
	setTo1(temp => from1);
	setFrom1Label(prevFrom1Label => to1Label);
	setTo1Label(tempLabel => from1Label);
  }
  const handleSwapMulty2 =() =>{
	const temp=from2;
	const tempLabel = from2Label;
	setFrom2(prevFrom2 => to2);
	setTo2(temp => from2);
	setFrom2Label(prevFrom2Label => to2Label);
	setTo2Label(tempLabel => from2Label);
  }
  const handleSwapMulty3 =() =>{
	const temp=from3;
	const tempLabel = from3Label;
	setFrom3(prevFrom3 => to3);
	setTo3(temp => from3);
	setFrom3Label(prevFrom3Label => to3Label);
	setTo3Label(tempLabel => from3Label);
  }
  const handleSwapMulty4 =() =>{
	const temp=from4;
	const tempLabel = from4Label;
	setFrom4(prevFrom4 => to4);
	setTo4(temp => from4);
	setFrom4Label(prevFrom4Label => to4Label);
	setTo4Label(tempLabel => from4Label);
  }
  const handleSwapMulty5 =() =>{
	const temp=from5;
	const tempLabel = from5Label;
	setFrom5(prevFrom5 => to5);
	setTo5(temp => from5);
	setFrom5Label(prevFrom5Label => to5Label);
	setTo5Label(tempLabel => from5Label);
  }
	const changeHed = (hedval) =>{
		let xx="   ✓" ;
		setHed(hedval);
		if (hedval === "One-way") 
		{  
			setOne(xx);
			setTwo("");
			setMulti("");
			setDtview("none");
			setOnetwoView({});
			setMulticityView({ display: 'none' });
			setDivv3({ display: 'none' }); 
			setDivv4({ display: 'none' }); 
			setDivv5({ display: 'none' }); 
			setDivDisplayCount(2);
			setJourneyType(1);
		}else if (hedval === "Round-trip") 
		{
			setOne("");
			setTwo(xx);
			setMulti("");
			setDtview("block");
			setOnetwoView({});
			setMulticityView({ display: 'none' });
			setDivv3({ display: 'none' }); 
			setDivv4({ display: 'none' }); 
			setDivv5({ display: 'none' }); 
			setDivDisplayCount(2);
			setJourneyType(2);
		}else
		{
			setFrom1("");
			setTo1("");
			setFrom2("");
			setTo2("");
			setFrom3("");
			setTo3("");
			setFrom4("");
			setTo4("");
			setFrom5("");
			setTo5("");

			setOne("");
			setTwo("");
			setMulti(xx);
			setDtview("block");
			setOnetwoView({ display: 'none' });
			setMulticityView({});
			setJourneyType(3);
		}

	}
	const changeClass = (clssval,jtype) =>{
		
		setClss(clssval);
		setCabinClass(jtype);
		
		// Perform any desired actions with the element
		divRefs.current.forEach((el,index) => {
	
			if (el.id) {
				el.style.display = 'none';
			  }
		  });
		divRefs.current.forEach((el,index) => {
	
			if (index === (jtype-1) && el.id) {
				el.style.display = 'block';
			  }
		  });
	}
	const mystyle = {
		backgroundColor: "#fff",
		width:"50%",
		border:"none"
	};
	const handleAutocompleteFocusFr2 = () => {
		// Set the desired value when the Autocomplete is focused
		//const valueToSet = 'Default Value'; // Replace this with your desired default value
		setFrom2(to1);
	  };
	  const handleAutocompleteFocusFr3 = () => {
		// Set the desired value when the Autocomplete is focused
		//const valueToSet = 'Default Value'; // Replace this with your desired default value
		setFrom3(to2);
	  };
	  const handleAutocompleteFocusFr4 = () => {
		// Set the desired value when the Autocomplete is focused
		//const valueToSet = 'Default Value'; // Replace this with your desired default value
		setFrom4(to3);
	  };
	  const handleAutocompleteFocusFr5 = () => {
		// Set the desired value when the Autocomplete is focused
		//const valueToSet = 'Default Value'; // Replace this with your desired default value
		setFrom5(to4);
	  };
  return (
     <div>
       <header className="header">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-lg-12">
			<div className="text-center headlines">
				<div className="text-white slogan-desc">Your Trip Starts Here</div>
				<div class="ol-usp-simple center">
					<div class="ol-usp-simple-text ol-usp-simple-text__hover">
						<div class="onlineSimple-container__separate"></div>
						<div class="ol-usp-simple-text__icon"></div>
						<div class="ol-usp-simple-text__textContainer">
							<span class="ol-usp-simple-text__textContainer__title" data-redirect="false">Secure Payment</span>
							<span class="ol-usp-simple-text__textContainer__title__dashline"></span>
						</div>
					</div>

					<div class="ol-usp-simple-text ol-usp-simple-text__hover">
						<div class="onlineSimple-container__separate"></div>
						<div class="ol-usp-simple-text__icon"></div>
						<div class="ol-usp-simple-text__textContainer">
							<span class="ol-usp-simple-text__textContainer__title" data-redirect="false">Support in Approx. 30s</span>
							<span class="ol-usp-simple-text__textContainer__title__dashline"></span>
						</div>
					</div>
				</div>
			</div>	
            <div className="box">
			<form onSubmit={formik.handleSubmit}>
              <div className="bookingFrom homepagebooking bg-white p-2">
					
					<div className="panel with-nav-tabs homeSearchCont product-desc">
						<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
							<li className={`nav-item  ${actB1}`}> 
							  <a className="nav-link active" id="booking1-tab" data-bs-toggle="tab" onClick={handleShow(1)} href="javascript:void(0);" role="tab"><i className="fa fa-plane" aria-hidden="true"> </i> Flights</a>
							</li>
							<li className={`nav-item  ${actB2}`}>
							  <a className="nav-link" id="booking2-tab" data-bs-toggle="tab" onClick={handleShow(2)}  href="javascript:void(0);" role="tab"><i className="fa fa-bed" aria-hidden="true"> </i> Hotels </a>
							</li>
							
							<li className="nav-item">
							  <a className="nav-link" id="booking3-tab" data-bs-toggle="tab" href="javascript:void(0);" role="tab"><i className="fa fa-train" aria-hidden="true"> </i> Trains</a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" id="booking4-tab" data-bs-toggle="tab" href="javascript:void(0);" role="tab"><i className="fa fa-car" aria-hidden="true"> </i> Cars</a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" id="booking5-tab" data-bs-toggle="tab" href="javascript:void(0);" role="tab"><i className="fa fa-cubes" aria-hidden="true"> </i> Attractions & Tickets</a>
							</li>
							<li className="nav-item">
							  <a className="nav-link" id="booking6-tab" data-bs-toggle="tab" href="javascript:void(0);" role="tab"><i className="fa fa-cogs" aria-hidden="true"> </i> Bundle & Save</a>
							</li>
						 </ul>
					  </div>
					 {/* </form> */}
					  <div className="tab-content pt-3">

						<div className={`tab-pane fade ${showActB1}`} id="booking1" role="tabpanel"> 
					  
							<div className="container bookingview p-3">
								<div className="row">
									<div className="col-lg-2 col-md-2 col-3 p-1">
										<div className="dropdown">
										  <a className="btn btn-flights dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="fa fa-plane" aria-hidden="true"> </i> {hed}
										  </a>

										  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
											<li><a className="dropdown-item"  onClick={()=>changeHed("One-way")}   href="javascript:void(0);">One-way  {one} </a></li>
											<li><a className="dropdown-item" onClick={()=>changeHed("Round-trip")} href="javascript:void(0);">Round-trip {two} </a></li>
											<li><a className="dropdown-item"  onClick={()=>changeHed("Multi-city")}  href="javascript:void(0);">Multi-city {multi} </a></li>
										  </ul> 
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-3 p-1">
										<div id="passengerDropdown" className="dropdown">
										<a
											className="btn btn-flights dropdown-toggle"
											href="javascript:void(0);"
											role="button"
											id="dropdownMenuLink"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											onClick={handleDropdownToggle}
											>
											<i className="fa fa-user" aria-hidden="true"> </i>{passengerscount}
										  </a>
										
										  <ul className={`dropdown-menu userslinklist ${showdiv}`} aria-labelledby="dropdownMenuLink" style={{display:displaydiv}}>
											<li><small className="dropdown-item userslink"> Please select the exact number of passengers <br /> to view the best prices </small></li>
											<li>
												<a className="dropdown-item" >
													<div className="row">
														<div className="col-lg-8">
															<p className="mb-0">Adults<br /><small style={{color:"#7e7979"}}>11 yrs Above</small></p>
														</div>
													
														<div className="col-lg-4">
															<div className="qty-input">
																<button className="qty-count qty-count--minus" type="button" onClick={() => setAdults(prevAdults => Math.max(prevAdults - 1, 0))}>-</button>
																<input className="product-qty" type="number" name="product-qty" min="0" max="10" value={adults} readOnly />
																<button className="qty-count qty-count--add" type="button" onClick={() => setAdults(prevAdults => Math.min(prevAdults + 1, 10))}>+</button>
														
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-8">
															<p className="mb-0">Children<br /><small style={{color:"#7e7979"}}>2-11 yrs</small></p>
														</div>
													
														<div className="col-lg-4">
															<div className="qty-input">
																	<button className="qty-count qty-count--minus" type="button" onClick={() => setChildren(prevChildren => Math.max(prevChildren - 1, 0))}>-</button>
																	<input className="product-qty" type="number" name="product-qty" min="0" max="10" value={children} readOnly />
																	<button className="qty-count qty-count--add" type="button" onClick={() => setChildren(prevChildren => Math.min(prevChildren + 1, 10))}>+</button>
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-8">
															<p className="mb-0">Infants (Lap)<br /><small style={{color:"#7e7979"}}>0–2 yr</small></p>
														</div>
													
														<div className="col-lg-4">
															<div className="qty-input">
																<button className="qty-count qty-count--minus" type="button" onClick={() => setInfants(prevInfants => Math.max(prevInfants - 1, 0))}>-</button>
																<input className="product-qty" type="number" name="product-qty" min="0" max="10" value={infants} readOnly />
																<button className="qty-count qty-count--add" type="button" onClick={() => setInfants(prevInfants => Math.min(prevInfants + 1, 10))}>+</button>
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<div className="dropdown-item text-end">
													<div className="cmn_btn">
														<a className="btn" onClick={handleDoneClick}>Done</a>
													</div>
												</div>
											</li>
										  </ul>
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-3 p-1">
										<div className="dropdown">
										  <a className="btn btn-flights dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="fa fa-users" aria-hidden="true"> </i> {clss}
										  </a>

										  <ul className="dropdown-menu economylist" aria-labelledby="dropdownMenuLink">
											<li className="active">
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-10" onClick={()=>changeClass("All",1)} ><p className="mb-0">All</p></div>
														<div className="col-lg-2" style={{display:"none"}} id="div1" ref={(el) => (divRefs.current[0] = el)}><i className="fa fa-check" aria-hidden="true"></i></div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);"> 
												{/* fghfjh */}
													<div className="row">
														<div className="col-lg-10"  onClick={()=>changeClass("Economy",2)}><p className="mb-0">Economy</p></div>
														<div className="col-lg-2" style={{display:"none"}} id="div2" ref={(el) => (divRefs.current[1] = el)}><i className="fa fa-check" aria-hidden="true"></i></div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-10"   onClick={()=>changeClass("Premium Economy",3)}><p className="mb-0">Premium Economy</p></div>
														<div className="col-lg-2" style={{display:"none"}} id="div3" ref={(el) => (divRefs.current[2] = el)}><i className="fa fa-check" aria-hidden="true"></i></div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-10"   onClick={()=>changeClass("Business",4)}><p className="mb-0">Business </p></div>
														<div className="col-lg-2" style={{display:"none"}} id="div4" ref={(el) => (divRefs.current[3] = el)}><i className="fa fa-check" aria-hidden="true"></i></div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-10"   onClick={()=>changeClass("Premium Business",5)}><p className="mb-0">Premium Business</p></div>
														<div className="col-lg-2" style={{display:"none"}} id="div5" ref={(el) => (divRefs.current[4] = el)}><i className="fa fa-check" aria-hidden="true"></i></div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-10"   onClick={()=>changeClass("First",6)}><p className="mb-0">First</p></div>
														<div className="col-lg-2" style={{display:"none"}} id="div6" ref={(el) => (divRefs.current[5] = el)}><i className="fa fa-check" aria-hidden="true"></i></div>
													</div>	
												</a>
											</li>
										  </ul>
										</div>
									</div>
									<div className="col-lg-4 col-md-4  col-0 notview text-end text-right"></div>
									
										<div className="col-lg-2 col-md-2 col-3 text-end text-right " >
											<div className="checkbox" >
												{/* <input type="checkbox" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
												<label for="show_sub_agent_rate" className="checkedsec"> Bundle & Save </label> */}
											</div>
										</div>
									
									</div>


								
								<div className="row pt-2 segment-info-wrapper filghts"  style={onetwoView} >
								<div className="col-lg-10  ">
								    <div className="row p-0">
										 <div className="col-lg-3 citynames col-md-3 col-sm-3 ps-1 pt-1 pb-1 col-xs-3 mb-0">
										 <label>From :</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={departLabel}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setDepart(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setDepartLabel(newValue.label); // Update state with label
        } else {
            setDepartLabel('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={depart || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/>
										
										
										<div className="switch-wrapper v-center" onClick={handleSwap}> <img src="images/roate.png" className="rotate" />
										<i className="fa fa-plane" aria-hidden="true"></i>
										</div>
									 </div>
									 <div className="col-lg-3 citynames col-md-3 col-sm-3  pe-1  pt-1 pb-1  col-xs-3 mb-0">
									 <label>To :</label>
	 <Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={arriveLabel}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setArrive(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setArriveLabel(newValue.label); // Update state with label
        } else {
            setArriveLabel('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={arrive || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 
		
											</div>
									 <div className="col-lg-3 citynames col-md-3 col-sm-3    p-1  col-xs-3 mb-0" >
									
										<label>Departure :</label>
										{/* <input type="date" name="fromdt" value={fromdt}  onChange={handleDateChange}   min={currentDate}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff"}} />	
														 */}
										<div style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
										<DatePicker
											name="fromdt"
											value={moment(fromdt).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'fromdt')}
											dateFormat="dd/MM/yyyy"
											minDate={today}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											style={{ width: '200px'}}
											/>
											</div>
									</div>
									<div className="col-lg-3 citynames col-md-3 col-sm-3    p-1  col-xs-3 mb-0" >
										<label style={{display:dtview}}>Return :</label>							
										{/* <input  type="date" name="returndt" value={returndt}  onChange={handleDateChange}   min={fromdt}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff",display:dtview}} />					 */}
										<div  style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px",display:dtview}}>
										<DatePicker
											name="returndt"
											value={moment(returndt).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'returndt')}
											dateFormat="dd/MM/yyyy" 
											minDate={minimDate}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											/>	
											</div>			 
									</div>
								</div>
								</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
										<div className="searchcmnss_btn">
										{/* <i className="fa fa-search" aria-hidden="true"> </i> */}
										<Button type="submit" className="submitebtn" variant="contained" color="primary"  style={{marginTop:"25px"}}>
                                                                {formik.isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }}/> : 'Search' } &nbsp;&nbsp; <i className="fa fa-search" aria-hidden="true"> </i>
                                                                   </Button>
											{/* <a className="btn"  onClick={handleClickButton}><i className="fa fa-search" aria-hidden="true"> </i> Search</a> */}
										</div>
									</div>
								</div>














								<div className="row pt-2 segment-info-wrapper filghts" style={multicityView}>
									 <div className="col-lg-6  ">
										 <div className="row p-0">
										 <div className="col-lg-6 citynames col-md-6 col-sm-6 ps-1  pt-1 pb-1 col-xs-6 mb-0">
										 <label>From:</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={from1Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setFrom1(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setFrom1Label(newValue.label); // Update state with label
        } else {
            setFrom1Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={from1 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 
										<div className="switch-wrapper v-center" onClick={handleSwapMulty1} > <img src="images/roate.png" className="rotate" />
										<i className="fa fa-plane" aria-hidden="true"></i>
										</div>
									 </div>
									 <div className="col-lg-6 citynames col-md-6 col-sm-6 pe-1  pt-1 pb-1 col-xs-6 mb-0">
									 <label>To :</label>
<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={to1Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setTo1(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setTo1Label(newValue.label); // Update state with label
        } else {
            setTo1Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={to1 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 							 
									
		
											</div>
									    </div> 
									 </div>
									 <div className="col-lg-3 col-md-3 col-sm-3 citynames p-1 col-xs-3 mb-0" >
									
										<label>Departure :</label>
										{/* <input type="date" name="traveldt1" value={traveldt1}  onChange={handleDateChange}   min={currentDate}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff"}} />					 */}
										<div  style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
										<DatePicker
											name="traveldt1"
											value={moment(traveldt1).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'traveldt1')}
											dateFormat="dd/MM/yyyy"
											minDate={today}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											style={{ width: '200px'}}
											/>	
											</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0" >
										
									</div>
									
									
								</div>

								<div className="row pt-2 segment-info-wrapper filghts" style={multicityView}>
									 <div className="col-lg-6  ">
										 <div className="row p-0">
										 
										 <div className="col-lg-6 citynames col-md-6 col-sm-6 ps-1  pt-1 pb-1 col-xs-6 mb-0">
										 <label>From:</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={from2Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setFrom2(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setFrom2Label(newValue.label); // Update state with label
        } else {
            setFrom2Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={from2 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/>
										
										<div className="switch-wrapper v-center" onClick={handleSwapMulty2} > <img src="images/roate.png" className="rotate" />
										<i className="fa fa-plane" aria-hidden="true"></i>
										</div>
									 </div>
									 <div className="col-lg-6 citynames col-md-6 col-sm-6 pe-1  pt-1 pb-1 col-xs-6 mb-0">
									 <label>To :</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={to2Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setTo2(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setTo2Label(newValue.label); // Update state with label
        } else {
            setTo2Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={to2 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 
		
										
											</div>
									    </div> 
									 </div>
									 <div className="col-lg-3 col-md-3 citynames col-sm-3 p-1 col-xs-3 p-1 mb-0" >
									
										<label>Departure :</label>
										{/* <input type="date" name="traveldt2" value={traveldt2}   onChange={handleDateChange}   min={traveldt1}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff"}} />					 */}
										<div  style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
										<DatePicker
											name="traveldt2"
											value={moment(traveldt2).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'traveldt2')}
											dateFormat="dd/MM/yyyy" 
											minDate={minimDate2}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											/>
											</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0" >
										
									</div>
									
									
								</div>

{/* div 3 ....................................................................*/}
								
								<div className="row pt-2 segment-info-wrapper filghts" style={divv3}>
									 <div className="col-lg-6  ">
										 <div className="row p-0">
										 <div className="col-lg-6 citynames col-md-6 col-sm-6 ps-1  pt-1 pb-1 col-xs-6 mb-0">
										 <label>From:</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={from3Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setFrom3(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setFrom3Label(newValue.label); // Update state with label
        } else {
            setFrom3Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={from3 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/>
										
										<div className="switch-wrapper v-center" onClick={handleSwapMulty3} > <img src="images/roate.png" className="rotate" />
										<i className="fa fa-plane" aria-hidden="true"></i>
										</div>
									 </div>
									 <div className="col-lg-6 citynames col-md-6 col-sm-6 pe-1  pt-1 pb-1 col-xs-6 mb-0">
									 <label>To:</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={to3Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setTo3(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setTo3Label(newValue.label); // Update state with label
        } else {
            setTo3Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={to3 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 
		
										
											</div>
									    </div> 
									 </div>
									 <div className="col-lg-3 col-md-3 citynames col-sm-3 p-1 col-xs-3 p-1 mb-0" >
									
										<label>Departure :</label>
										{/* <input type="date" name="traveldt3" value={traveldt3}   onChange={handleDateChange}   min={traveldt2}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff"}} />					 */}
										<div  style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
										<DatePicker
											name="traveldt3"
											value={moment(traveldt3).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'traveldt3')}
											dateFormat="dd/MM/yyyy" 
											minDate={minimDate3}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											/>	
											</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0" >
										
									</div>
									
									
								</div>
{/* div 4 ....................................................................*/}
								
									<div className="row pt-2 segment-info-wrapper filghts" style={divv4}>
									 <div className="col-lg-6  ">
										 <div className="row p-0">
										 <div className="col-lg-6 citynames col-md-6 col-sm-6 ps-1  pt-1 pb-1 col-xs-6 mb-0">
										 <label>From:</label>
	<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={from4Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setFrom4(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setFrom4Label(newValue.label); // Update state with label
        } else {
            setFrom4Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={from4 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/>
										
										<div className="switch-wrapper v-center" onClick={handleSwapMulty4} > <img src="images/roate.png" className="rotate" />
										<i className="fa fa-plane" aria-hidden="true"></i>
										</div>
									 </div>
									 <div className="col-lg-6 citynames col-md-6 col-sm-6 pe-1  pt-1 pb-1 col-xs-6 mb-0">
									 <label>To:</label>
	 <Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={to4Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setTo4(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setTo4Label(newValue.label); // Update state with label
        } else {
            setTo4Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={to4 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 
		
										
											</div>
									    </div> 
									 </div>
									 <div className="col-lg-3 col-md-3 citynames col-sm-3 p-1 col-xs-3 p-1 mb-0" >
									
										<label>Departure :</label>
										{/* <input type="date" name="traveldt4" value={traveldt4}   onChange={handleDateChange}   min={traveldt3}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff"}} />					 */}
										<div  style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
										<DatePicker
											name="traveldt4"
											value={moment(traveldt4).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'traveldt4')}
											dateFormat="dd/MM/yyyy" 
											minDate={minimDate4}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											/>
										</div>	
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0" >
										
									</div>
									
									
									</div>
								{/* div 5 ....................................................................*/}
								
									<div className="row pt-2  segment-info-wrapper filghts" style={divv5}>
									 <div className="col-lg-6 ">
										 <div className="row p-0">
										 <div className="col-lg-6 citynames col-md-6 col-sm-6 ps-1  pt-1 pb-1 col-xs-6 mb-0">
										 <label>From:</label>
	 <Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={from5Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setFrom5(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setFrom5Label(newValue.label); // Update state with label
        } else {
            setFrom5Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={from5 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/>
										
										<div className="switch-wrapper v-center" onClick={handleSwapMulty5} > <img src="images/roate.png" className="rotate" />
										<i className="fa fa-plane" aria-hidden="true"></i>
										</div>
									 </div>
									 <div className="col-lg-6 citynames col-md-6 col-sm-6 pe-1  pt-1 pb-1 col-xs-6 mb-0">
										 <label>To:</label>
<Autocomplete
    disablePortal
    id="combo-box-demo"
    className="form-control w-100"
    value={to5Label}
    onChange={(e, newValue) => {
        if (newValue !== null) {
			setTo5(JSON.stringify(newValue.value).replace(/^"(.*)"$/, '$1'));
            setTo5Label(newValue.label); // Update state with label
        } else {
            setTo5Label('');
        }
    }}
    options={data}
    sx={{ width: 350 }}
    renderInput={(params) => (
        <TextField
            {...params}
            label={to5 || "Choose..."} // Display label or "Choose..." if no selection
            InputLabelProps={{ shrink: true }}
            variant="outlined"
        />
    )}
    renderOption={(props, option) => (
        <li {...props} style={{ color: 'grey', fontWeight: '500', fontSize: '0.8rem' }}>{option.label}</li>
    )}
/> 
		
										
											</div>
									    </div> 
									 </div>

									 <div className="col-lg-3 col-md-3 citynames col-sm-3 p-1 col-xs-3 p-1 mb-0" >
									
										<label>Departure :</label>
										{/* <input type="date" name="traveldt5" value={traveldt5}   onChange={handleDateChange}   min={traveldt4}  className="form-control w-100 " placeholder="dd-mm-yyyy"    style={{backgroundColor:"#fff"}} />					 */}
										<div  style={{height:"40px",marginTop:"0px",border: "1px solid rgb(216 203 203)",padding: "2px",borderRadius:"5px"}}>
										<DatePicker
											name="traveldt5"
											value={moment(traveldt5).format('DD/MM/YYYY')}
											onChange={(date) => handleDateChange(date, 'traveldt5')}
											dateFormat="dd/MM/yyyy" 
											minDate={minimDate5}
											showYearDropdown
											yearDropdownItemNumber={2} // Set the number of years to show in the dropdown
											placeholderText="Depart On"
											inputProps={{ autoComplete: 'off' }}
											wrapperClassName="date-picker-wrapper" 
											/>	
											</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0" >
										
									</div>
									
									
						</div>
{/* div end .............................................................................*/}

								<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={multicityView}>
								<div className="col-lg-9 col-md-9 col-sm-9 col-xs-9" >

								</div>
								<div className="col-lg-3 col-md-3 col-sm-3 col-xs-3" >
								<div className="d-flex plusandminius" >
									<button type="button" style={buttonStyle} onClick={handleClickPlus}>
									<svg
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									width="24"
									height="24"
									>
									<path d="M0 0h24v24H0z" fill="none" />
									<path d="M12 2c.55 0 1 .45 1 1v8h8c.55 0 1 .45 1 1s-.45 1-1 1h-8v8c0 .55-.45 1-1 1s-1-.45-1-1v-8H3c-.55 0-1-.45-1-1s.45-1 1-1h8V3c0-.55.45-1 1-1z" />
									</svg>
									</button>			
								
									<button  type="button" style={buttonStyle} onClick={handleClickMinus}>
										<svg
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										width="24"
										height="24"
										>
										<path d="M0 0h24v24H0z" fill="none" />
										<path d="M19 13H5v-2h14v2z" />
										</svg>
									</button>
									<div className="searchcmnss_btn">
										{/* <a className="btn"  onClick={handleClickButton}><i className="fa fa-search" aria-hidden="true"> </i> Search</a> */}
										<Button type="submit" className="submitebtn" variant="contained" color="primary" style={{marginTop:"7px"}}>
											{formik.isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }}/> : 'Search' } &nbsp;&nbsp; <i className="fa fa-search" aria-hidden="true"> </i>
										</Button>
									</div>	
								</div>
							</div>
						</div>















							</div>
						</div> 


						<div className={`tab-pane fade ${showActB2}`} id="booking2" role="tabpanel">
							<HotelSearch />
						</div>
						







						 <div className="tab-pane fade" id="booking3" role="tabpanel">
							 <div className="container bookingview p-3">
								 <div className="row pb-2">
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
										<div className="radio d-flex">
											<input type="radio" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
											<label for="show_sub_agent_rate" className="checkedsec"> Mainland China </label>
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
										<div className="radio d-flex">
											<input type="radio" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
											<label for="show_sub_agent_rate" className="checkedsec"> United Kingdom </label>
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
										<div className="radio d-flex">
											<input type="radio" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
											<label for="show_sub_agent_rate" className="checkedsec"> South Korea </label>
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
										<div className="radio d-flex">
											<input type="radio" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
											<label for="show_sub_agent_rate" className="checkedsec"> Europe</label>
										</div>
									</div>
								</div>
								<div className="row pb-2">
									 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
										 <div className="form-group citynames">
											<label>From</label>
											<div className="select_box multiple cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
												<input type="text" name="travellersText"  id="selectTravellerFlt" value="City, airport, region, landmark....." className="travell_input e_input" readonly="readonly" placeholder="City, airport, region, landmark....." autocomplete="none" />
											</div>
												<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
													<ul className="mb-0">		
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
														</li>
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
														</li>
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
														</li>
														
													</ul>
												</div>
											<div className="switch-wrapper v-center"> <img src="images/roate.png" className="rotate" />
											<i className="fa fa-train" aria-hidden="true"></i>
											</div>
										 </div>
									 </div>
									 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
										 <div className="form-group citynames">
											<label>To</label>
											<div className="select_box multiple cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
												<input type="text" name="travellersText"  id="selectTravellerFlt" value="City, airport, region, landmark....." className="travell_input e_input" readonly="readonly" placeholder="City, airport, region, landmark....." autocomplete="none" />
											</div>
												<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
													<ul className="mb-0">		
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
														</li>
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
														</li>
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
														</li>
														
													</ul>
												</div>
										 </div>
									 </div>
									 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0 p-0">
										 <div className="form-group">
											<label>Departure Time</label>
											<div className="row">
												<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-0" >
													<input type="text" name="daterange" value="01/01/2018 - 01/15/2018" placeholder="01/01/2018 - 01/15/2018" />
												</div>
												<div className="col-lg-4 p-0 col-md-4 col-sm-4 col-xs-4 mb-0" >
													<input type="time" name="time"  placeholder="0.00" />
												</div>
											</div>
										 </div>
									 </div>
									 <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0">
										<div className="form-group">
											<div className="extraview">
											<label>Rooms and Guests</label>
											<div className="select_box cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
												<input type="text" name="travellersText"  id="selectTravellerFlt" value="1 Adult " className="travell_input e_input" readonly="readonly" placeholder="1  " autocomplete="none" />
											</div>
											<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
												<div className="trvlrInfoDv">
													<div className="col-md-12 col-md-12 col-sm-12 col-xs-12">
														<div className="form-groups userslinklist ">
															<div className="row">
																<label className="col-lg-4 e_label">Adults</label>
																<label className="col-lg-4 e_label">16+ yrs</label>
																<div className="col-lg-4">
																	<div className="qty-input">
																		<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																		<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																		<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
																	</div>
																</div>
															</div>
															<div className="row">
																<label className="col-lg-4 e_label">Children</label>
																<label className="col-lg-4 e_label">5-15 yrs</label>
																<div className="col-lg-4">
																	<div className="qty-input">
																		<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																		<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																		<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-12">
																	<p>Children under 5 years old may travel for free when not occupying a seat.
																	Passenger types must be selected based on the passenger's age on the day of travel.</p>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-8">
																	<p><i className="fa fa-id-card" aria-hidden="true"> </i> Railcards (0/1)</p>
																</div>
																<div className="col-lg-4">
																	<a href="javascript:void(0);">Add Railcard</a>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-12">
																	<div className="cmn_btn">
																		<a className="btn">Done</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												  </div>
											   </div>
											</div>
										</div>
									 </div>
									 <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 mb-0">
										<div className="searchcmnss_btn">
											<a className="btn"><i className="fa fa-search" aria-hidden="true"> </i> Search</a>
										</div>
									 </div>
								</div>
							 </div>
						</div>
									 

						 
						 <div className="tab-pane fade" id="booking4" role="tabpanel">
							 <div className="container bookingview p-3">
								 <div className="row pt-2 pb-2">
									<ul className="nav pb-3 nav-tabs cars nav-tabs-custom" id="myTab" role="tablist" style={mystyle}>
									  <li className="nav-item pt-2" role="presentation">
										<button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Car Rentals</button>
									  </li>
									  <li className="nav-item pt-2" role="presentation">
										<button className="nav-link text-black" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Airport Transfers</button>
									  </li>
									</ul>
									
									<div className="tab-content" id="myTabContent">
									  <div className="tab-pane pt-2 fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
									  
											<div className="pt-5 pb-2">
												<div className="checkbox d-flex">
													<input type="checkbox" name="show_sub_agentRate" id="show_sub_agent_rate" value="yes" autocomplete="none" />
													<label for="show_sub_agent_rate" className="checkedsec"> Drop off at a different location </label>
												</div>
											</div>
											<div className="row pb-2">
												 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
													 <div className="form-group citynames">
														<label>Pick-up Location</label>
																<div className="select_box multiple cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
																<input type="text" name="travellersText"  id="selectTravellerFlt" value="City, airport, region, landmark....." className="travell_input e_input" readonly="readonly" placeholder="City, airport, region, landmark....." autocomplete="none" />
															</div>
														<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
															<ul className="mb-0">		
																<li className="dropdown-item bg-lightview">
																	<h6>Asia</h6>
																</li>
																<li className="dropdown-item grid_item">
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a><a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																	<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
																</li>
																
															</ul>
														</div>
													 </div>
												 </div>
												 <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3 mb-0 p-0">
													 <div className="form-group">
														<label>Pick-up Date</label>
														<div className="row">
															<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-0" >
																<input type="text" name="daterange" value="01/01/2018 - 01/15/2018" placeholder="01/01/2018 - 01/15/2018" />
															</div>
															<div className="col-lg-4 p-0 col-md-4 col-sm-4 col-xs-4 mb-0" >
																<input type="time" name="time"  placeholder="0.00" />
															</div>
														</div>
													 </div>
												 </div>
												 <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3 mb-0 p-0">
													 <div className="form-group">
														<label>Drop-off Date</label>
														<div className="row">
															<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-0" >
																<input type="text" name="daterange" value="01/01/2018 - 01/15/2018" placeholder="01/01/2018 - 01/15/2018" />
															</div>
															<div className="col-lg-4 p-0 col-md-4 col-sm-4 col-xs-4 mb-0" >
																<input type="time" name="time"  placeholder="0.00" />
															</div>
														</div>
													 </div>
												 </div>
												 <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 mb-0">
													<div className="searchcmnss_btn">
														<a className="btn"><i className="fa fa-search" aria-hidden="true"> </i> Search</a>
													</div>
												 </div>
											</div>
									  </div>
									  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
											<div className="row pt-5 pb-2">
												 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
													 <div className="form-group">
														<label>From</label>
														<input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="City, airport, region, landmark....." autocomplete="none" />
														<div className="switch-wrapper v-center"> <img src="images/roate.png" className="rotate" />
														<i className="fa fa-car" aria-hidden="true"></i>
														</div>
													 </div>
												 </div>
												 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
													 <div className="form-group">
														<label>To</label>
														<input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="City, airport, region, landmark....." autocomplete="none" />
													 </div>
												 </div>
												 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0 p-0">
													 <div className="form-group">
														<label>Departure Time</label>
														<div className="row">
															<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-0" >
																<input type="text" name="daterange" value="01/01/2018 - 01/15/2018" placeholder="01/01/2018 - 01/15/2018" />
															</div>
															<div className="col-lg-4 p-0 col-md-4 col-sm-4 col-xs-4 mb-0" >
																<input type="time" name="time"  placeholder="0.00" />
															</div>
														</div>
													 </div>
												 </div>
												 <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 mb-0">
													<div className="form-group">
														<div className="extraview">
														<label>Rooms and Guests</label>
														<div className="select_box cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
															<input type="text" name="travellersText"  id="selectTravellerFlt" value="1 Adult " className="travell_input e_input" readonly="readonly" placeholder="1  " autocomplete="none" />
														</div>
														<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
															<div className="trvlrInfoDv">
																<div className="col-md-12 col-md-12 col-sm-12 col-xs-12">
																	<div className="form-groups userslinklist ">
																		<div className="row">
																			<label className="col-lg-4 e_label">Adults</label>
																			<label className="col-lg-4 e_label">16+ yrs</label>
																			<div className="col-lg-4">
																				<div className="qty-input">
																					<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																					<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																					<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<label className="col-lg-4 e_label">Children</label>
																			<label className="col-lg-4 e_label">5-15 yrs</label>
																			<div className="col-lg-4">
																				<div className="qty-input">
																					<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																					<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																					<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
																				</div>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-lg-12">
																				<small>Children under 5 years old may travel for free when not occupying a seat.
																				Passenger types must be selected based on the passenger's age on the day of travel.</small>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-lg-8">
																				<p><i className="fa fa-id-card" aria-hidden="true"> </i> Railcards (0/1)</p>
																			</div>
																			<div className="col-lg-4">
																				<a href="javascript:void(0);">Add Railcard</a>
																			</div>
																		</div>
																		<div className="row">
																			<div className="col-lg-12">
																				<div className="cmn_btn">
																					<a className="btn">Done</a>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															  </div>
														   </div>
														</div>
													</div>
												 </div>
												 <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 mb-0">
													<div className="searchcmnss_btn">
														<a className="btn"><i className="fa fa-search" aria-hidden="true"> </i> Search</a>
													</div>
												 </div>
											</div>
									  </div>
									</div>
								</div>
								
							 </div>
						</div>
						
					
						 <div className="tab-pane fade" id="booking5" role="tabpanel">
							 <div className="container bookingview p-3">
								<div className="row pb-2">
									 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
										 <div className="form-group citynames">
											<label>Destination</label>
											<div className="select_box multiple cmn_InputArrow dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
												<input type="text" name="travellersText"  id="selectTravellerFlt" value="City, airport, region, landmark....." className="travell_input e_input" readonly="readonly" placeholder="City, airport, region, landmark....." autocomplete="none" />
											</div>
												<div className="dropdown-menu" aria-labelledby="dropdownMenu2">
													<ul className="mb-0">		
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
														</li>
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
														</li>
														<li className="dropdown-item bg-lightview">
															<h6>Asia</h6>
														</li>
														<li className="dropdown-item grid_item">
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span> </a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
															<a href="javascript:void(0);" className="city-name" data-testid="cityLabel_name_2"><span>Taipei</span></a>
														</li>
														
													</ul>
												</div>
										 </div>
									 </div>
									 <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-0">
										 <div className="form-group">
											<label>Keywords (Optional)</label>
											<input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="City, airport, region, landmark....." autocomplete="none" />
										 </div>
									 </div>
									 <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 p-0 mb-0">
										<div className="searchcmnss_btn">
											<a className="btn"><i className="fa fa-search" aria-hidden="true"> </i> Search</a>
										</div>
									 </div>
								</div>
							 </div>
						</div>
						
						
						 <div className="tab-pane fade" id="booking6" role="tabpanel">
							 <div className="container bookingview p-3">
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 p-1">
										<div className="dropdown">
										  <a className="btn btn-flights dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="fa fa-plane" aria-hidden="true"> </i> Round-trip
										  </a>

										  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
											<li><a className="dropdown-item" href="javascript:void(0);">One-way</a></li>
											<li><a className="dropdown-item" href="javascript:void(0);">Round-trip</a></li>
											<li><a className="dropdown-item" href="javascript:void(0);">Multi-city</a></li>
										  </ul>
										</div>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 p-1">
										<div className="dropdown">
										  <a className="btn btn-flights dropdown-toggle" href="javascript:void(0);" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
											<i className="fa fa-user" aria-hidden="true"> </i>10 Passengers
										  </a>

										  <ul className="dropdown-menu userslinklist" aria-labelledby="dropdownMenuLink">
											<li><small className="dropdown-item userslink"> Please select the exact number of passengers <br /> to view the best prices </small></li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-7">
															<p className="mb-0">Rooms<br /><small></small></p>
														</div>
													
														<div className="col-lg-5">
															<div className="qty-input">
																<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-7">
															<p className="mb-0">Adults<br /><small></small></p>
														</div>
													
														<div className="col-lg-5">
															<div className="qty-input">
																<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-7">
															<p className="mb-0">Children<br /><small className="gray">2-11 yrs</small></p>
														</div>
													
														<div className="col-lg-5">
															<div className="qty-input">
																<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<a className="dropdown-item" href="javascript:void(0);">
													<div className="row">
														<div className="col-lg-7">
															<p className="mb-0">Infants (Lap)<br /><small className="gray">14 days–1 yr</small></p>
														</div>
													
														<div className="col-lg-5">
															<div className="qty-input">
																<button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
																<input className="product-qty" type="number" name="product-qty" min="0" max="10" value="1" />
																<button className="qty-count qty-count--add" data-action="add" type="button">+</button>
															</div>
														</div>
													</div>	
												</a>
											</li>
											<li>
												<div className="dropdown-item text-end">
													<div className="cmn_btn">
														<a className="btn">Done</a>
													</div>
												</div>
											</li>
										  </ul>
										</div>
									</div>
								</div>
								
								<div className="row pt-2 filghts">
									 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
										<input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="City, airport, region, landmark....." autocomplete="none" />
										<div className="switch-wrapper v-center"> <img src="images/roate.png" alt="rotate" className="rotate" />
											<i className="fa fa-plane" aria-hidden="true"></i>
											</div>
									 </div>
									 <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-0">
										<input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="City, airport, region, landmark....." autocomplete="none" />
									 </div>
									 <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 mb-0" >
										<input type="text" name="daterange" value="01/01/2018 - 01/15/2018" placeholder="01/01/2018 - 01/15/2018" />
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
										
									</div>
								</div>
								<div className="row pt-2 filghts">
									 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-0">
										<input type="text" name="txt_booking_refno" maxlength="10" id="txt_booking_refno" value="" className="form-control" placeholder="City, airport, region, landmark....." autocomplete="none" />
									 </div>
									 <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 mb-0" >
										<input type="text" name="daterange" value="01/01/2018 - 01/15/2018" placeholder="01/01/2018 - 01/15/2018" />
									</div>
									<div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
										<div className="savecmnss_btn">
											<a className="btn"><i className="fa fa-search" aria-hidden="true"> </i> Search</a>
										</div>
									</div>
								</div>
								
							 </div>
						</div>
						
						
			   </div>
			</div>  
</form>
            
			</div>
        </div>
		</div>
        </div>
    </header> 
    </div>
  )
}

export default Header

